import ahoy from "ahoy.js";
ahoy.configure({
  visitsUrl: "/bhoy/visits",
  eventsUrl: "/bhoy/events",
});

ahoy.trackView();
ahoy.trackClicks("a, button, input[type=submit]");
ahoy.trackSubmits("form");
ahoy.trackChanges("input, textarea, select");

export { ahoy };
