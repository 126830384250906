import { Notifier } from "@airbrake/browser";

export const airbrake = new Notifier({
  projectId: 281701,
  projectKey: "db0465246dc0b779a74fa4a8e83efa66",
  environment: window.initData?.env ?? "production",
});

airbrake.addFilter(function (notice) {
  if (notice.context.environment !== "production") {
    return null;
  }
  return notice;
});
