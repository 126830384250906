import * as React from "react";

// Spinner by Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL
export const Spinner = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#000"
  >
    <g fill="none" fill-rule="evenodd" stroke-width="2">
      <circle cx="22" cy="22" r="1">
        <animate
          attributeName="r"
          begin="0s"
          dur="1.8s"
          values="20; 1"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0.165, 0.84, 0.44, 1"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          dur="1.8s"
          values="0; 1"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0.3, 0.61, 0.355, 1"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="22" cy="22" r="1">
        <animate
          attributeName="r"
          begin="-0.9s"
          dur="1.8s"
          values="20; 1"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0.165, 0.84, 0.44, 1"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-opacity"
          begin="-0.9s"
          dur="1.8s"
          values="0; 1"
          calcMode="spline"
          keyTimes="0; 1"
          keySplines="0.3, 0.61, 0.355, 1"
          repeatCount="indefinite"
        />
      </circle>
    </g>
  </svg>
);

export const Search = ({ style }: { style: React.CSSProperties }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="search"
    class="svg-inline--fa fa-search fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={style}
  >
    <path
      fill="currentColor"
      d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"
    />
  </svg>
);

export const GlobeEuropeDuo = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496 512"
      style={style}
      className={className}
    >
      <defs>
        <style>{".fa-secondary{opacity:.4}"}</style>
      </defs>
      <path
        d="M487.54,320.4H438.9a15.8,15.8,0,0,1-11.4-4.8l-32-32.6a11.92,11.92,0,0,1,.1-16.7l12.5-12.5v-8.7a11.37,11.37,0,0,0-3.3-8l-9.4-9.4a11.37,11.37,0,0,0-8-3.3h-16a11.31,11.31,0,0,1-8-19.3l9.4-9.4a11.37,11.37,0,0,1,8-3.3h32a11.35,11.35,0,0,0,11.3-11.3v-9.4a11.35,11.35,0,0,0-11.3-11.3H376.1a16,16,0,0,0-16,16v4.5a16,16,0,0,1-10.9,15.2l-31.6,10.5a8,8,0,0,0-5.5,7.6v2.2a8,8,0,0,1-8,8h-16a8,8,0,0,1-8-8,8,8,0,0,0-8-8H269a8.14,8.14,0,0,0-7.2,4.4l-9.4,18.7a15.94,15.94,0,0,1-14.3,8.8H216a16,16,0,0,1-16-16V199a16,16,0,0,1,4.7-11.3l20.1-20.1a24.77,24.77,0,0,0,7.2-17.5,8,8,0,0,1,5.5-7.6l40-13.3a11.66,11.66,0,0,0,4.4-2.7l26.8-26.8a11.31,11.31,0,0,0-8-19.3H280l-16,16v8a8,8,0,0,1-8,8H240a8,8,0,0,1-8-8v-20a8.05,8.05,0,0,1,3.2-6.4l82.42-60.08A247.79,247.79,0,0,0,248,8C111,8,0,119,0,256S111,504,248,504a251.57,251.57,0,0,0,32.1-2.06V448.4a16,16,0,0,0-16-16H243.9c-10.8,0-26.7-5.3-35.4-11.8l-22.2-16.7a45.42,45.42,0,0,1-18.2-36.4V343.6a45.46,45.46,0,0,1,22.1-39l42.9-25.7a46.13,46.13,0,0,1,23.4-6.5h31.2a45.62,45.62,0,0,1,29.6,10.9l43.2,37.1h18.3a32,32,0,0,1,22.6,9.4l17.3,17.3.08.08C432,359.06,440,375.62,440,393.37V413A247.11,247.11,0,0,0,487.54,320.4ZM187.4,157.1a11.37,11.37,0,0,1-8,3.3h-16a11.31,11.31,0,0,1-8-19.3l25.4-25.4a11.31,11.31,0,0,1,19.3,8v16a11.37,11.37,0,0,1-3.3,8Z"
        class="fa-secondary"
      />
      <path
        d="M187.4,157.1l9.4-9.4a11.37,11.37,0,0,0,3.3-8v-16a11.31,11.31,0,0,0-19.3-8l-25.4,25.4a11.31,11.31,0,0,0,8,19.3h16A11.37,11.37,0,0,0,187.4,157.1ZM418.78,347.18l-.08-.08-17.3-17.3a32,32,0,0,0-22.6-9.4H360.5l-43.2-37.1a45.62,45.62,0,0,0-29.6-10.9H256.5a46.13,46.13,0,0,0-23.4,6.5l-42.9,25.7a45.46,45.46,0,0,0-22.1,39v23.9a45.42,45.42,0,0,0,18.2,36.4l22.2,16.7c8.7,6.5,24.6,11.8,35.4,11.8h20.2a16,16,0,0,1,16,16v53.54A247.57,247.57,0,0,0,440,413V393.37C440,375.62,432,359.06,418.78,347.18ZM317.62,17.92,235.2,78a8.05,8.05,0,0,0-3.2,6.4v20a8,8,0,0,0,8,8h16a8,8,0,0,0,8-8v-8l16-16h20.7a11.31,11.31,0,0,1,8,19.3l-26.8,26.8a11.66,11.66,0,0,1-4.4,2.7l-40,13.3a8,8,0,0,0-5.5,7.6,24.77,24.77,0,0,1-7.2,17.5l-20.1,20.1A16,16,0,0,0,200,199v25.3a16,16,0,0,0,16,16h22.1a15.94,15.94,0,0,0,14.3-8.8l9.4-18.7a8.14,8.14,0,0,1,7.2-4.4h3.1a8,8,0,0,1,8,8,8,8,0,0,0,8,8h16a8,8,0,0,0,8-8v-2.2a8,8,0,0,1,5.5-7.6l31.6-10.5a16,16,0,0,0,10.9-15.2v-4.5a16,16,0,0,1,16-16h36.7a11.35,11.35,0,0,1,11.3,11.3v9.4a11.35,11.35,0,0,1-11.3,11.3h-32a11.37,11.37,0,0,0-8,3.3l-9.4,9.4a11.31,11.31,0,0,0,8,19.3h16a11.37,11.37,0,0,1,8,3.3l9.4,9.4a11.37,11.37,0,0,1,3.3,8v8.7l-12.5,12.5a11.92,11.92,0,0,0-.1,16.7l32,32.6a15.8,15.8,0,0,0,11.4,4.8h48.64A248.29,248.29,0,0,0,496,256C496,143.18,420.71,48,317.62,17.92Z"
        class="fa-primary"
      />
    </svg>
  );
};

export const ChartAreaDuo = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={style}
      className={className}
    >
      <defs>
        <style>{".fa-secondary{opacity:.4}"}</style>
      </defs>
      <path
        d="M500 384a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H12a12 12 0 0 1-12-12V76a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v308z"
        class="fa-secondary"
      />
      <path
        d="M390.1 164.2L480 352H96V248l86.8-144.7a12 12 0 0 1 19.9-1L288 216l84.7-56.5a12 12 0 0 1 17.4 4.7z"
        class="fa-primary"
      />
    </svg>
  );
};
export const ChartBarDuo = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={style}
      className={className}
    >
      <defs>
        <style>{".fa-secondary{opacity:.4}"}</style>
      </defs>
      <path
        d="M512 400v32a16 16 0 0 1-16 16H32a32 32 0 0 1-32-32V80a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v304h432a16 16 0 0 1 16 16z"
        class="fa-secondary"
      />
      <path
        d="M275.2 96h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8zm-96 128h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8zm288-160h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8zm-96 96h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8z"
        class="fa-primary"
      />
    </svg>
  );
};
export const UsersDuo = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      style={style}
      className={className}
    >
      <defs>
        <style>{".fa-secondary{opacity:.4}"}</style>
      </defs>
      <path
        d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
        class="fa-secondary"
      />
      <path
        d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
        class="fa-primary"
      />
    </svg>
  );
};
export const AlignJustifyDuo = ({
  className,
  style,
}: OptionalClassNameProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      style={style}
      className={className}
    >
      <defs>
        <style>{".fa-secondary{opacity:.4}"}</style>
      </defs>
      <path
        d="M432 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-256H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
        class="fa-secondary"
      />
      <path
        d="M432 288H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-256H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
        class="fa-primary"
      />
    </svg>
  );
};
export const FilterDuo = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={style}
      className={className}
    >
      <defs>
        <style>{".fa-secondary{opacity:.4}"}</style>
      </defs>
      <path
        d="M505 41L320 225.93V488c0 19.51-22 30.71-37.76 19.66l-80-56A24 24 0 0 1 192 432V226L7 41C-8 25.87 2.69 0 24 0h464c21.33 0 32 25.9 17 41z"
        class="fa-secondary"
      />
    </svg>
  );
};
export const InfoSquareDuo = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      className={className}
      style={style}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="info-square"
      class="svg-inline--fa fa-info-square fa-w-14"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-176 86a42 42 0 1 1-42 42 42 42 0 0 1 42-42zm56 254a12 12 0 0 1-12 12h-88a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h12v-64h-12a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12v100h12a12 12 0 0 1 12 12z"
          opacity="0.4"
        />
        <path
          class="fa-primary"
          fill="currentColor"
          d="M224 202a42 42 0 1 0-42-42 42 42 0 0 0 42 42zm44 134h-12V236a12 12 0 0 0-12-12h-64a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h12v64h-12a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12z"
        />
      </g>
    </svg>
  );
};
export const InfoSolid = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      className={className}
      style={style}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="info"
      class="svg-inline--fa fa-info fa-w-6"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 192 512"
    >
      <path
        fill="currentColor"
        d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z"
      />
    </svg>
  );
};
export const SearchDuo = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      className={className}
      style={style}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="search"
      class="svg-inline--fa fa-search fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M208 80a128 128 0 1 1-90.51 37.49A127.15 127.15 0 0 1 208 80m0-80C93.12 0 0 93.12 0 208s93.12 208 208 208 208-93.12 208-208S322.88 0 208 0z"
          opacity="0.4"
        />
        <path
          class="fa-primary"
          fill="currentColor"
          d="M504.9 476.7L476.6 505a23.9 23.9 0 0 1-33.9 0L343 405.3a24 24 0 0 1-7-17V372l36-36h16.3a24 24 0 0 1 17 7l99.7 99.7a24.11 24.11 0 0 1-.1 34z"
        />
      </g>
    </svg>
  );
};
export const LinkDuo = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      className={className}
      style={style}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="link"
      class="svg-inline--fa fa-link fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M44.45 252.59l37.11-37.1c9.84-9.84 26.78-3.3 27.29 10.6a184.45 184.45 0 0 0 9.69 52.72 16.08 16.08 0 0 1-3.78 16.61l-13.09 13.09c-28 28-28.9 73.66-1.15 102a72.07 72.07 0 0 0 102.32.51L270 343.79A72 72 0 0 0 270 242a75.64 75.64 0 0 0-10.34-8.57 16 16 0 0 1-6.95-12.6A39.86 39.86 0 0 1 264.45 191l21.06-21a16.06 16.06 0 0 1 20.58-1.74A152.05 152.05 0 0 1 327 400l-.36.37-67.2 67.2c-59.27 59.27-155.7 59.26-215 0s-59.26-155.72.01-214.98z"
          opacity="0.4"
        />
        <path
          class="fa-primary"
          fill="currentColor"
          d="M410.33 203.49c28-28 28.9-73.66 1.15-102a72.07 72.07 0 0 0-102.32-.49L242 168.21A72 72 0 0 0 242 270a75.64 75.64 0 0 0 10.34 8.57 16 16 0 0 1 6.94 12.6A39.81 39.81 0 0 1 247.55 321l-21.06 21.05a16.07 16.07 0 0 1-20.58 1.74A152.05 152.05 0 0 1 185 112l.36-.37 67.2-67.2c59.27-59.27 155.7-59.26 215 0s59.27 155.7 0 215l-37.11 37.1c-9.84 9.84-26.78 3.3-27.29-10.6a184.45 184.45 0 0 0-9.69-52.72 16.08 16.08 0 0 1 3.78-16.61z"
        />
      </g>
    </svg>
  );
};
export const ClipboardListDuo = ({
  className,
  style,
}: OptionalClassNameProp) => {
  return (
    <svg
      className={`${className} svg-inline--fa fa-link fa-w-16`}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="link"
      role="img"
    >
      <path
        d="M336 64h-80a64 64 0 0 1 64 64H64a64 64 0 0 1 64-64H48a48 48 0 0 0-48 48v352a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V112a48 48 0 0 0-48-48zM96 424a24 24 0 1 1 24-24 23.94 23.94 0 0 1-24 24zm0-96a24 24 0 1 1 24-24 23.94 23.94 0 0 1-24 24zm0-96a24 24 0 1 1 24-24 23.94 23.94 0 0 1-24 24zm224 176a8 8 0 0 1-8 8H168a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8zm0-96a8 8 0 0 1-8 8H168a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8zm0-96a8 8 0 0 1-8 8H168a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8z"
        opacity={0.4}
        class="fa-secondary"
      />
      <path
        d="M96 376a24 24 0 1 0 24 24 23.94 23.94 0 0 0-24-24zm0-96a24 24 0 1 0 24 24 23.94 23.94 0 0 0-24-24zm0-96a24 24 0 1 0 24 24 23.94 23.94 0 0 0-24-24zM256 64a64 64 0 0 0-128 0 64 64 0 0 0-64 64h256a64 64 0 0 0-64-64zm-64 24a24 24 0 1 1 24-24 23.94 23.94 0 0 1-24 24z"
        class="fa-primary"
      />
    </svg>
  );
};
export const ExternalLinkDuo = ({
  className,
  style,
}: OptionalClassNameProp) => {
  return (
    <svg
      className={className}
      style={style}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="external-link"
      class="svg-inline--fa fa-external-link fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M400 320h32a16 16 0 0 1 16 16v128a48 48 0 0 1-48 48H48a48 48 0 0 1-48-48V112a48 48 0 0 1 48-48h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H64v320h320V336a16 16 0 0 1 16-16z"
          opacity="0.4"
        />
        <path
          class="fa-primary"
          fill="currentColor"
          d="M484 224h-17.88a28 28 0 0 1-28-28v-.78L440 128 192.91 376.91A24 24 0 0 1 159 377l-.06-.06L135 353.09a24 24 0 0 1 0-33.94l.06-.06L384 72l-67.21 1.9A28 28 0 0 1 288 46.68V28a28 28 0 0 1 28-28h158.67A37.33 37.33 0 0 1 512 37.33V196a28 28 0 0 1-28 28z"
        />
      </g>
    </svg>
  );
};
export const QuestionDuo = ({ className, style }: OptionalClassNameProp) => {
  return (
    <svg
      className={className}
      style={style}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="question"
      class="svg-inline--fa fa-question fa-w-12"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <g class="fa-group">
        <path
          class="fa-secondary"
          fill="currentColor"
          d="M182.4,373.5c-38.3,0-69.3,31-69.3,69.3s31,69.3,69.3,69.3c38.3,0,69.3-31,69.3-69.3 C251.7,404.5,220.7,373.5,182.4,373.5z"
          opacity="0.4"
        />
        <path
          class="fa-primary"
          fill="currentColor"
          d="M367.9,153.6c0,116-125.3,117.8-125.3,160.6v5.8c0,13.3-10.7,24-24,24h-72.5c-13.3,0-24-10.7-24-24v-9.8 c0-61.8,46.9-86.5,82.3-106.4c30.4-17,49-28.6,49-51.2c0-29.8-38-49.6-68.8-49.6c-39.1,0-57.8,18.1-82.8,49.4 c-8.1,10.2-22.9,12-33.3,4.1l-43.1-32.7c-10.3-7.8-12.6-22.3-5.2-32.9C60.9,32.7,112.6,0,192.4,0C277.3,0,367.9,66.3,367.9,153.6z"
        />
      </g>
    </svg>
  );
};

export type OptionalClassNameProp = { className?: string; style?: any };
export type IconWithClass = { (props: OptionalClassNameProp): JSX.Element };
